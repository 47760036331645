function hide(x) {
    document.getElementById(x).style.display = 'none';
}

function show(x) {
    document.getElementById(x).style.display = 'block';
}

window.initVerificationModal = function() {
    document.body.onclick = function () {
        document.getElementById('modal').style.display = 'none'
    }
}

window.openVerificationModal = function() {
    event.stopPropagation();
    window.changeModalScreen('modal-screen-index');
}

window.hideModal = function() {
    event.stopPropagation();
    hide('modal');
}

window.changeModalScreen = function(screen){
    show('modal');
    hide('modal-screen-index');
    hide('modal-screen1');
    hide('modal-screen2');
    show(screen);
    if(screen == 'modal-screen1'){
        document.getElementById('modal-screen1').querySelector('textarea').focus();
    }
}
