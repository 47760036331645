
var existingCharts = {};
window.buildProgressChart = function(target, valid, invalid, unknown, count){
    var data = {
        labels: [
            'Valid',
            'Invalid / Disposable',
            'Accept-all / Unknown'
        ],
        datasets: [{
            label: 'My First Dataset',
            data: [valid, invalid, unknown],
            backgroundColor: [
                'rgb(12,171,6)',
                'rgb(184,7,7)',
                'rgb(231,109,0)'
            ],
            hoverOffset: 4
        }]
    };
    var config = {
        type: 'doughnut',
        data: data,
        options: {
            plugins: {
                legend: {
                    display: false,
                    labels: {
                        color: 'rgb(255, 99, 132)'
                    }
                }
            },

            animation: {
                duration: 0 // general animation time
            },
            hover: {
                animationDuration: 0 // duration of animations when hovering an item
            },
            responsiveAnimationDuration: 0 // animation duration after a resize
        }
    };

    if(existingCharts[target]) existingCharts[target].destroy(); // Allow redrawing chart

    var myChart = new Chart(
        document.getElementById(target),
        config
    );

    document.getElementById(target).parentElement.querySelector('.js-chart-text').innerHTML = `
    ${count > 1000 ? (count / 1000) + "k" : count}
      <br/>
      ${count > 1 ? 'verifications' : 'verification'}
    `

    existingCharts[target] = myChart;
}
