
window.makeAccordion = function(contentSelector, plusSelector, minusSelector){
    let elements = document.querySelectorAll(contentSelector);
    for (let i = 0; i < elements.length; i++) {
        let content = elements[i];
        content.style.display = 'none';

        let plusButton = content.parentElement.querySelector(plusSelector);
        let minusButton = content.parentElement.querySelector(minusSelector);

        minusButton.style.display = 'none';
        minusButton.onclick = function () {
            plusButton.style.display = 'block';
            minusButton.style.display = 'none';
            content.style.display = 'none';
            event.preventDefault();
            return false;
        }
        plusButton.onclick = function () {
            plusButton.style.display = 'none';
            minusButton.style.display = 'block';
            content.style.display = 'block';
            event.preventDefault();
            return false;
        }
    }
}

window.makeDropzone = function(dropzone, input){
    dropzone.ondragover = function (e) {
        e.preventDefault();
        this.classList.add('dragover');
    };
    dropzone.ondragleave = function (e) {
        e.preventDefault();
        this.classList.remove('dragover');
    };
    dropzone.onclick = function (e) {
        input.click();
    }
    dropzone.ondrop = function (e) {
        e.preventDefault();
        this.classList.remove('dragover');
        input.files = e.dataTransfer.files;
        dropzone.innerHTML = input.files[0].name;
    }
    input.onchange = function(e){
        dropzone.innerHTML = input.files[0].name;
    }
}

window.makeTooltip = function(trigger, tooltip){
    trigger.onmouseenter = function(){
        tooltip.setAttribute('aria-hidden', false);
        tooltip.style.left = (-100 + this.getBoundingClientRect().width/2) + 'px';
        tooltip.classList.add('ember-tooltip-show');
    }

    trigger.onmouseleave = function(){
        tooltip.setAttribute('aria-hidden', true); tooltip.classList.remove('ember-tooltip-show')
    }
}

window.makeMobileMenu = function(trigger, menu){
    let isOpened = false;

    trigger.onclick = function(e){
        e.preventDefault();

        this.classList.toggle('is-active');
        menu.classList.toggle('is-opened');
    }
}
