import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

import { createConsumer } from "@rails/actioncable"
window.createConsumer = createConsumer;
import "chartkick/chart.js"
require ('./ui.js')
require ('./chart.js')
require ('./verification.js')
require ('./public.js')
