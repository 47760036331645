window.addEventListener('DOMContentLoaded', () => {
  const links = [...document.getElementById("pricing-faq").getElementsByTagName('a')];
  links.forEach((link) => {
    link.addEventListener("click", toggleFaq);
  });

  var userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  var eurosTimezone = ["Europe/Vienna", "Europe/Brussels", "Europe/Sofia", "Asia/Famagusta", "Asia/Nicosia", "Europe/Prague", "Europe/Berlin", "Europe/Busingen", "Europe/Copenhagen", "Europe/Tallinn", "Africa/Ceuta", "Atlantic/Canary", "Europe/Madrid", "Europe/Helsinki", "Europe/Paris", "Europe/Belfast", "Europe/London", "Europe/Athens", "Europe/Zagreb", "Europe/Budapest", "Europe/Dublin", "Europe/Rome", "Europe/Vilnius", "Europe/Luxembourg", "Europe/Riga", "Europe/Malta", "Europe/Amsterdam", "Europe/Warsaw", "Atlantic/Azores", "Atlantic/Madeira", "Europe/Lisbon", "Europe/Bucharest", "Europe/Stockholm", "Europe/Ljubljana", "Europe/Bratislava"];

  if (eurosTimezone.includes(userTimeZone)) {
    document.body.classList.add('is-eu');
    setSubscriptionButtonsCurrency('eur');
  }
  else {
    setSubscriptionButtonsCurrency('usd');
  }

  function setSubscriptionButtonsCurrency(currency) {
    [].forEach.call(document.getElementsByClassName('subscription-button'), function (link) {
      const newUrl = new URL(link.attributes.href.value);
      newUrl.searchParams.set('currency', currency);
      link.attributes.href.value = newUrl.href;
    });
  }
});

function toggleFaq(event){
  event.preventDefault();
  event.stopPropagation();
  const closestLi = this.closest('li.question');

  if(closestLi.classList.contains('invisible')) {
    closestLi.classList.remove('invisible');
    closestLi.classList.add('visible');
  }
  else {
    closestLi.classList.remove('visible');
    closestLi.classList.add('invisible');
  }
}
